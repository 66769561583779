<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">ASAL PENGIRIMAN</label>
                    <!-- <v-select :options="senderOptions" v-model="form.sender_addr_id" :reduce="opt => opt.id" /> -->
                    <v-select required label="name" name="sender_addr_id" :filterable="false" :options="addrOptions1" v-model="form.sender_addr_id" :reduce="(opt) => opt.id" @search="onSearch1">
                    <template slot="no-options"> Ketik asal pengiriman... </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    </v-select>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">TUJUAN PENGIRIMAN</label>
                    <v-select required label="name" name="addr_id" :filterable="false" :options="addrOptions2" v-model="form.addr_id" :reduce="(opt) => opt.id" @search="onSearch2">
                    <template slot="no-options"> Ketik tujuan pengiriman... </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    </v-select>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">BERAT (Kg)</label>
                    <input class="form-control" v-model="form.weight" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="weight" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" :disabled="checking" v-on:click="search" class="btn btn-primary btn-md float-right"><i class="fas fa-search"></i>&nbsp;Cek Sekarang <span v-if="checking" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="mt-2">
                <div class="input-group border mb-3" v-for='item in resultOngkir'>
            <div style="min-height: 50px; flex: 1 1 auto;width: 1%;" class="w-auto p-3">
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <img :src="getKurirLogo(item.kurir)" style="height:40px;" />
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <div class="description text-left mt-0">
                            <span class="description-text" style="color: gray;">Service</span>
                            <h5 class="description-header" style="margin-top: 5px;">{{ item.service }}</h5>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <div class="description text-left mt-0">
                            <span class="description-text" style="color: gray;">Estimasi</span>
                            <h5 class="description-header" style="margin-top: 5px;">{{ item.etd_from }} - {{ item.etd_thru }} hari</h5>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <div class="description text-left mt-0">
                            <span class="description-text" style="color: gray;">Tarif Ongkir Normal</span>
                            <h5 class="description-header" style="margin-top: 5px;">{{ formatPrice(item.maxPrice) }}</h5>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <div class="description text-left mt-0">
                            <span class="description-text" style="color: gray;">Tarif Ongkir bosCOD</span>
                            <h5 class="description-header" style="margin-top: 5px;">{{ formatPrice((item.price - item.disc) + item.ppn_ongkir) }} &nbsp; <i class="fas fa-info-circle" :title="'Diskon '+item.percent_disc+'%'"></i></h5>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <span class="input-group-append">
                <button type="button" class="btn btn-info btn-flat" @click="detail2(item.no)"><i class="fa fa-search"></i> Detail</button>
            </span> -->
                </div>
                </div>
            </div>
        </div>

        <!-- <div
            v-for="row in resultOngkir"
            v-bind:key="row.service"
            v-bind:value="row.service"
            class="col-md-12">
        
        </div> -->
        
    </section>
</template>
<script>
import { createTable, authFetch, formatCurrency } from '@/libs/hxcore';
import $ from 'jquery';
// import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    name: 'CekOngkir',
    components: {
        vSelect,
        // datepicker,
    },
    data() {
        return {
            errors: [],
            addrOptions1: [],
            addrOptions2: [],
            senderOptions: [],
            resultOngkir: [],
            roles: '',
            checking: false,
            form: {
                addr_id: '',
                sender_addr_id: '',
                weight: 1,
            },
            // tgl: '',
            // kelas: '',
        }
    },
    created: function() {
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)
        //this.loadAddress();
        this.loadSenderAddress();
    },
    methods: {
        formatPrice(data){
            return formatCurrency(data);
        },
        getKurirLogo(kurir) {

            const fileName = kurir.toLowerCase();

            return require(`../assets/img/${fileName}.png`) // the module request
        },
        search: function() {
            let self = this;
            self.form.addr_id = self.form.addr_id;
            self.form.sender_addr_id = self.form.sender_addr_id;
            self.form.weight = self.form.weight;
            if (!self.form.sender_addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Asal gudang belum dipilih!", ``, "error");
            }
            if (!self.form.addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Tujuan pengiriman belum dipilih!", ``, "error");
            }
            this.loadOngkir(self.form);
        },
        loadAddress() {
            let route;
                route = "/order/cekongkir/address";
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        // this.sender = json.data;
                        this.addrOptions1.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                        this.addrOptions2.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                    }
                });
            });
        },
        loadSenderAddress() {
            authFetch('/order/cekongkir/asalgudang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.senderOptions = js.data;
                $('.loading-overlay').removeClass('show');
            });
        },
        onSearch1(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions1 = json.items));
                    loading(false);
                });
            }
        },
        onSearch2(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions2 = json.items));
                    loading(false);
                });
            }
        },
        loadOngkir(params) {
            const self = this;
            let data;
            let route;
            data = Object.keys(params)
            .map(
                (key) =>
                encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
            )
            .join("&");
            route = "/order/cekongkir/cek?" + data;
            // var weight = self.formItem.weight;
            // if (!weight) weight = 1;
            // if (s_id) {
            //     route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}`;
            // } else {
            //     route = "/order/nilai_ongkir/854/887";
            // }
            // if (self.form.shipper_id) {
            //     route = route + '?shipper_id=' + self.form.shipper_id;
            // }
            this.checking = true;
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    console.log("data ", json);
                    self.resultOngkir = json;
                    this.checking = false;
                });
            });
        },
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;
    }
}
</script>